html {
  scroll-behavior: smooth;
	background-color: black;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  margin: 0;
}

* {
	box-sizing: border-box;
	transition: all ease-in-out 250ms;
}

img {
	max-width: 100%;
	height: auto;
}

a {
  color: white;
}

a:hover {
  color: white;
	
}

a:visited {
  color: white;
	
}

a:link {
color: white;
	
}

/* Start section hero */
.section-hero {
  padding: 0 0;
	color: #FFF;
	height: 180px;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
}

.section-hero .container {
  margin: 0px 20px;
}

.section-hero .container {
  padding-bottom: 10px;
}

.section-hero .container h1 {
  padding: 0px; 
  margin: 0px;
  font-size: 3em;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: .8em;
  text-align: left;
  font-weight: 700;
  color: white;
}

.section-hero .container p {
  font-size: 1.3em;
  text-align: left;
  line-height: normal;
  margin-top: -.1em;
  margin-bottom: 0;
  color: white;
  box-sizing: border-box;
  transition: all ease-in-out 250ms;
}

@media (min-width: 37rem) {
  .section-hero {
    height: 250px;
  }
  .section-hero .container h1 {
    font-size: 4em;
  }
  .section-hero .container p {
    font-size: 1.5em;
  }
}

@media (min-width: 60rem) {
  .section-hero .container h1 {
    font-size: 5em;
  }
  .section-hero .container p {
    font-size: 2em;
  }
}

.section-hero.inventory {
  background-image: url(../public/img/hero-inventory.jpg);
}
.section-hero.portfolio {
  background-image: url(../public/img/hero-portfolio.jpg);
}
.section-hero.personal {
  background-image: url(../public/img/hero-personal.jpg);
}
.section-hero.events {
  background-image: url(../public/img/hero-events.jpg);
}
.section-hero.restorations {
  background-image: url(../public/img/hero-restore.jpg);
}
.section-hero.toneteam {
  background-image: url(../public/img/hero-toneteam.jpg);
}
.section-hero.blog {
  background-image: url(../public/img/hero-blog.jpg);
}

.section-hero.about {
  background-image: url(../public/img/hero-friends.jpg);
}
.section-hero.appraisals {
  background-image: url(../public/img/hero-appraisals.jpg);
}
.section-hero.contact {
  background-image: url(../public/img/hero-contact.jpg);
}
.section-hero.friends {
  background-image: url(../public/img/hero-friends.jpg);
}
.section-hero.privacy {
  background-image: url(../public/img/hero-contact.jpg);
}
.section-hero.saleterms {
  background-image: url(../public/img/hero-saleterms.jpg);
}
.section-hero.services {
  background-image: url(../public/img/hero-services.jpg);
}
/* End section hero */

/* Start section text */
.container2 {
  padding: 0px 20px;
}
.section-text {
  width: 100%;
  height: auto;
  /* TODO: margin-top: -6em; */
  margin-bottom: 1em;
  padding: 0 0 0 0;
  background-color: black;
  font-family: 'Roboto Condensed', sans-serif;
}

.section-text h2 {
  font-size: 32px;
  font-weight: 700;
  font-style: condensed;
  text-align: left;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: 20px;
  margin-bottom: 0;
}

.section-text p {
  font-size: 1em;
  text-align: left;
  color: white;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
  line-height: 1.1;
  margin-left: 20px;
  margin-top: .5em;
}

@media (min-width: 37rem) {
  .section-text {
    margin-top: 0em;
    margin-bottom: 0em;
  }
  .section-text p {
    font-size: 1.2em;
  }
}

@media (min-width: 60rem) {
  .section-text {
    margin-top: 0em;
    margin-bottom: 0em;
    width: 60%;
  }
}
/* End section text */





.titleWhite {
	font-family: 'Roboto Condensed', sans-serif;
	line-height: .8em;
	font-size: 3em;
	margin-bottom: .2em;
	text-align: left;
	font-weight: 700;
	margin-top: 3em;
	margin-left: .3em;
	color: white;
}

.key-desc {
	font-size: 1.3em;
	text-align: left;
	margin-left: .8em;
	line-height: 1em;
	margin-bottom: 0em;
	margin-top: -.35em;
}

.home-hero {
	background-image: url(../public/img/hero-bg.jpg);
	background-size: cover;
	background-position: center !important;
  width: 100vw;
	padding: 10em 0;
	color: #FFF;
  box-sizing: border-box;
}

.hero {
  background-size: cover;
	background-position: top;
	padding: 10em 0;
	color: #FFF;
	height: 25em;
}

.parallax--bg,
.parallax--box {
	transition: initial;
}

@media (min-width:37rem) {
	.titleWhite {
		font-size: 4em;
		margin-left: .3em;
		margin-top: 2em;
	}

  .key-desc {
    font-size: 1.5em;
    text-align: left;
    margin-left: 1em;
    line-height: normal;
    margin-bottom: 0em;
    margin-top: -.5em;  
  }
}

@media (min-width:60rem) {
  .home-hero {
		height: 100vh;
		padding-top: 35vh;
	}

	.titleWhite {
		font-size: 5em;
		margin-left: .2em;
		margin-top: 1em;
	}

  .key-desc p {
		font-size: 2em;
	}
	
	.key-desc {
    font-size: 2em;
    text-align: left;
    margin-left: .7em;
    line-height: normal;
    margin-top: -.5em;
    margin-bottom: 0em;
	}
}





/* Start style for ImageUploader */
.btn {
  border: 1px solid rgba(144, 202, 249, 0.5);
  background-color: transparent;
  color: rgb(144, 202, 249);
  padding: 2px;
  padding-bottom: 0px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
}

.btn:hover {
  border: 1px solid rgba(144, 202, 249, 1);
}

.img-uploader-container {
  width: 100%;
  float: left;
  padding-left: 5px;
}

@media (max-width: 1199px) {
  .img-uploader-container {
      padding-left: 25px;
  }
}
@media (max-width: 991px) {
  .img-uploader-container {
      padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .img-uploader-container {
      padding-left: 35px;
  }
}
@media (max-width: 575px) {
  .img-uploader-container {
      padding-left: 0px;
  }
}

.img-uploader-picker {
  width: 200px;
  height: 200px;
  border: 4px dashed #ccc;
  border-radius: 25px;
  float: left;
  margin: 10px;
}

.img-uploader-item {
  float: left;
  width: 200px;
  height: 230px;
  margin: 10px;
}
.img-uploader-img {
  float: left;
  width: 200px;
  height: 200px;
  border-radius: 25px;
  border: 1px solid #ccc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center fixed;
}

.img-uploader-img-overlay {
  width: 200px;
  height: 200px;
  position: absolute;
  background-color: #ccc;
  border-radius: 25px;
  border: 1px solid #ccc;
  z-index: 1000;
  opacity: 0.5;
}

.img-uploader-item .spinner-border {
  position: absolute;
  margin-top: 84px;
  margin-left: 84px;
  z-index: 1100;
}

.img-uploader-remove {
  text-align: center;
}

.img-uploader-file {
  opacity: 0;
  height: 200px;
  width: 200px;
}

.waiting_wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
}

.waiting_progress {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  z-index: 1200;
}

.waiting_opac {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #000;
  opacity: 0.4;
}
/* End style for ImageUploader */

/* Start portfolio */
.portfolio {
	margin: 2em 0 0 0;
	padding: 2em 0 2em 0;
	width:100%;
}

.port-item {
	margin: 0 0 0 0;
	position: relative;
	float: left;
	padding: 0 0 0 0;
}

.port-item img {
	display: block;
}

.port-item img:hover {
	opacity: 0.5;
}
	
.port-item a {
	display: block;
	margin-top: 0;	
}

.port-desc {
	position: absolute;
	z-index: 100;
	bottom: 0em;
	left: 0em;
	right: 0em;
	margin-top: -2em;
	color: white;
	background: rgba(0,0,0,.6);
	padding-bottom: 2em;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.port-desc p {
	margin: 1em;
	color: white;
}

.port-desc a {
    font-size: .7rem;
    font-weight: 700;
}
	

@media (min-width: 37rem) {
    p {
        font-size: 1.2rem;
        line-height: 1.6;
    }

	.portfolio {
	    margin: 0 0 0 0;
    }
	
	.port-item {
		width: 25%;
		float: left;
		padding-right: .5em;
		padding-bottom: .5em;
	}
	
	.port-desc {
        position: absolute;
        z-index: 100;
        bottom: 0em;
        left: 0em;
        right: 0em;
        background: rgba(0,0,0,.6);
        padding-bottom: 2em;
        margin-right: .5em;
        margin-bottom: .5em;
    }
}


@media (min-width: 60rem) {
	.portfolio {
	    margin: 0em 0 0 0;
    }
	
	
	.port-item {
		width: 20%;
		overflow: hidden;
		padding-right: .5em;
		padding-bottom: .5em;
	}

	.port-desc {
		transform: translateY(150%);
		margin-right: .5em;
		margin-bottom: .5em;
	}

	.port-item:hover .port-desc {
		transform: translateY(0%);
		
	}
}	

/* portfolio 2 */
.portfolio2 {
	margin: 2em 0 0 0;
	padding: 2em 0 0 0;
	width:100%;
}

.port-item2 {
	margin: 0 0 0 0;
	position: relative;
	float: left;
	padding: 0 0 0 0;
	width:50%;
}

.port-item2 img {
	display: block;
	width: 100%;
}

.port-item2 img:hover {
	opacity: 0.5;
}
	
.port-item2 a {
	display: block;
	margin-top: 0;	
}

.port-desc2 {
	position: absolute;
	z-index: 100;
	bottom: 0em;
	left: 0em;
	right: 0em;
	margin-top: -2em;
	color: white;
	background: rgba(0,0,0,.6);
	padding-bottom: 2em;
}

.port-desc2 p {
	margin: 1em;
	color: white;
}
	

@media (min-width: 37rem) {
    .portfolio2 {
	    margin: 0 0 0 0;
    }
	
	.port-item2 {
		width: 25%;
		float: left;
		padding-right: .5em;
		padding-bottom: .5em;
	}
	
	.port-desc2 {
        position: absolute;
        z-index: 100;
        bottom: 0em;
        left: 0em;
        right: 0em;
        background: rgba(0,0,0,.6);
        padding-bottom: 2em;
        margin-right: .5em;
        margin-bottom: .5em;
    }
}


@media (min-width: 60rem) {
	.portfolio2 {
	    margin: 0em 0 0 0;
    }
	
	
	.port-item2 {
		width: 50%;
		overflow: hidden;
		padding-right: .5em;
		padding-bottom: .5em;
		
	}

	.port-desc2 {
		transform: translateY(150%);
		margin-right: .5em;
		margin-bottom: .5em;
		font-size: 25;
	}

	.port-item2:hover .port-desc2 {
		transform: translateY(-300%);
		
	}
}

.port-button {
    border: 2px solid #fff;
    margin-left: 10%;
    text-align: center;
    text-transform: uppercase;
    padding: .5em 1.75em;
    width: 80%;
    text-decoration: none;
    font-size: .7rem;
    font-weight: 700;
}

.port-button:hover {
    color: #777777;
}



.filter-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
}
/* End portfolio */

/* Start filters */
.filters-inline {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
}

.filters-collapsed {
    display: none;
}
/* End filters */

/* Start Quill overrides */
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow {
  border-color: rgba(255, 255, 255, 0.23) !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ql-container.ql-snow:hover{
  border-color: rgba(255, 255, 255, 1) !important;
  border-top: 1px solid rgba(255, 255, 255, 1) !important;
}
.ql-container.ql-snow.focus{
  border: 1px solid #90caf9 !important;
}
.rtl.MuiFormLabel-root {
  padding-left: 5px !important;
  padding-right: 5px !important;
  background-color: #000 !important;
}
.rtl.MuiFormLabel-root.focus {
  color: #90caf9;  
}

.blogImg {
  max-width: 600px;
}
/* End Quill overrides */

/* Start footer */
footer {
  min-height: 400px;
  background-color: #232323;
  text-align: left;
  padding: 40px 0;
}

footer li {
  list-style: none;
  text-align: center;
}
/* End footer */

.tagInput .MuiOutlinedInput-notchedOutline {
  border: none !important;
}