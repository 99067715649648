/* gallery
=================== */


.gallery {
	margin: 1em 0 0;
	maring-top: 1em;
}

.gal-item {
	margin: 0 0 0 0;
	position: relative;

}

.gal-item img {
	display: block;
	

}

@media (min-width: 37rem) {
	.gal-item {
		width: 50%;
		float: left;
		padding-right: .5em;
		padding-bottom: .5em;
	}
}


@media (min-width: 60rem) {
	.gal-item {
		width: 33.333333334%;
		overflow: hidden;
		padding-right: .5em;
		padding-bottom: .5em;
	}

}