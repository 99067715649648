header {
	position: absolute;
	left: 0;
	right: 0;
	margin: 1em;
}

nav {
	margin-top: .6em;
    display: none;
}

nav ul {
	display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: center;
}

nav li {
	display: inline-block;
	margin: 0 .1em 0 .3em;
}

.social2 {
	display: inline-block;
	
}

nav a {
	font-weight: 900;
	text-decoration: none;
	padding: .2em ;
	text-transform: uppercase;
	color: white;
	font-size: .8rem;
}

nav a:hover,
nav a:focus {
	color: #ddd;
}

nav a:visited {
	
	color:white;
}

.logo {
    float: inherit;
    position:relative;
    height: 34px;
}
@media (min-width: 37rem) {
    .logo {
        height: 44px;
    }
}
@media (min-width: 60rem) {
    .logo {
        height: 54px;
    }
}



.snIcon {
	width:25px;
	height: 25px;
	display: inline-block;
	margin: -.5em 0 0 0em;
	float:left;
}

#socialBlock {
    text-align: center !important;
    display: flex;
    gap: 10px;
    margin-left: auto;
}

@media (max-width: 960px) {
    nav ul {
        flex-wrap: wrap;
        width: 420px;
    }
    #socialBlock {
        width: 100%;
        justify-content: center;
    }
}

.nav-burger {
    position: absolute;
    right: 5px;
    top: 5px; 
}

@media (min-width: 37rem) {
    nav {
        display: flex;
    }
    .nav-burger {
        display: none;
    }
}

@media (min-width:25rem) {
    .titleFooter {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        font-size: 3em;
        text-align: center;
        margin-bottom: 1em;
        line-height: 1em;
    }


    header {
        position: absolute;
        left: 0;
        right: 0;
        margin: 1em;
    }


    nav {
        float:right;
        margin-top: -10px;
    }

    nav li {
        display: inline-block;
        margin: 0em .3em 0 .3em;
    }

    .social {
        display: inline-block;
        margin: .9em .0em 0 .0em;
    }
}

@media (min-width:60rem) {
    header {
        position: absolute;
        left: 0;
        right: 0;
        margin: 1em;
    }
    .logo {
        float: left;
    }

    nav {
        float: right;
        margin-top: 5px;
    }
}