@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');

.title {
	font-family: 'Roboto Condensed', sans-serif;
	line-height: .8em;
	font-size: 100px;
	margin-bottom: 1.5em;
	font-weight: 700;
	margin-top: 1em;
    text-align: center;
}

.title span {
	font-weight: 300;
	display: block;
	font-size: .75em;
}

@media (min-width:37rem) {
	.title {
		font-size: 7rem;
		margin-top: 2rem;
	}
}